.today button {
  color: white;
  background: #2196f3 !important;
  border: 0px solid #4285f4 !important;
}

.calendarContainer .selected button,
.calendarContainer .selected button:hover :not([disabled]),
.calendarContainer .selected button:active,
.calendarContainer .selected button:focus {
  background-color: #e33aa9 !important;
  color: #ffffff !important;
}

.selectToday {
  display: none !important;
}

.calendarContainer {
  box-shadow: none !important;
  width: 100%;
}

.calendarContainer .heading {
  width: 230px;
  height: 12px;
  font-size: 10px;
  padding: 0 15px;
}

.calendarContainer .heading button {
  height: 24px !important;
  width: 24px !important;
}

.calendarContainer .heading .title {
  width: fit-content !important;
}

.calendarContainer .daysOfWeek {
  padding-top: 5px;
  font-size: 10px;
}

.calendarContainer .dayPickerContainer .dayWrapper button {
  height: 22px !important;
  width: 22px !important;
  font-size: 10px;
}
